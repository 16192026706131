require('./components/smooth-slider/smooth-slider');
require('./components/videoPlayer');

var smoothSlider = new SmoothSlider({
    autoPlay: true,
    interval: 8000
});
var smoothSlider1 = new SmoothSlider1({
    autoPlay: true,
    interval: 10000
});

//tab-list 切换效果

//tab-content-active
var uEl=document.querySelectorAll('.tab-list')[0];
var contentEl=document.querySelectorAll('.tab-content')[0];
uEl.addEventListener('click',function(e){
    e.preventDefault();
    var tar=e.target;
    if(tar.nodeName=='A'){
        var aEls=uEl.querySelectorAll('a');
        var aLength=aEls.length;
        for(var i=0;i<aLength;i++){
            aEls[i].setAttribute('class','');
        }
        tar.setAttribute('class','active');
        var href=tar.getAttribute('href');
        var uls=contentEl.querySelectorAll('.article-list');
        var len=uls.length;
        for(var i=0; i<len;i++){
            uls[i].setAttribute('class','article-list');
        }
        contentEl.querySelector(href).setAttribute('class','article-list tab-content-active');
    }
})




