let jsBtnPlayers = document.querySelectorAll('.jsBtnPlayer');
Array.prototype.forEach.call(jsBtnPlayers, (btn)=>{
    btn.addEventListener('click', ()=>{
        let elVideoPlayer = btn.parentNode.getElementsByClassName('videoPlayer')[0];
        let videoPlayer = window.videojs(elVideoPlayer.id);
        videoPlayer.ready(()=>{
           videoPlayer.play();
        });
        videoPlayer.on('pause', ()=>{
            btn.style.display = 'block';
        });
        videoPlayer.on('play', ()=>{
            btn.style.display = 'none';
        });
    })
});